import React from "react";
import { cn } from "@/lib/utils";

export const Loading: React.FC<{
  readonly className?: string;
  readonly spinnerClassName?: string;
}> = ({ spinnerClassName, className }) => {
  return (
    <div className={cn("flex items-center justify-center", className)}>
      <div
        className={cn(
          "size-6 animate-spin rounded-full border-4 border-gray-500 border-t-transparent",
          spinnerClassName,
        )}
      />
    </div>
  );
};

export const FullContentLoading = ({
  children,
}: {
  readonly children?: React.ReactNode;
}) => {
  return (
    <div className="my-auto flex h-full w-full items-center justify-center">
      {children ?? <p>Loading...</p>}
    </div>
  );
};

export const Spinner: React.FC<{
  readonly className?: string;
}> = ({ className }) => {
  return (
    <div
      className={cn(
        "size-6 animate-spin rounded-full border-4 border-gray-500 border-t-transparent",
        className,
      )}
    />
  );
};
